import React from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import mapIconImg from '../images/mapIcon1.png'; // your custom marker image
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Define your locations array (you can also import it from another file)
const locations = [
  {
    name: 'Distributivni centar i Paket Shop Laktaši (Jakupovci)',
    address: 'Omladinska 66b, Jakupovci - Laktaši',
    location: [44.8885236, 17.2936099],
    workTimeWeek: '8.00 h - 20.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Sarajevo',
    address: 'Derviša Numića do br. 6',
    location: [43.849199, 18.3960655],
    workTimeWeek: '9.00 h - 17.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Tuzla',
    address: '15 maja bb - (RK Sjenjak)',
    location: [44.533680, 18.696593],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Bijeljina',
    address: 'Majevička 175',
    location: [44.7605027127338, 19.21332563501472],
    workTimeWeek: '9.00 h - 17.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Gračanica',
    address: 'Zlatnih ljiljana 22',
    location: [44.6917957, 18.3001417],
    workTimeWeek: '9.30 h - 17.30 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },

  {
    name: 'Distributivni centar i Paket Shop Banja Luka',
    address: 'Jovana Dučića 23a',
    location: [44.77625517, 17.183677221],
    workTimeWeek: '8.00 h - 21.30 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    deliveryTimeWeek: '7.30 h - 21.00 h',
    takingTimeWeek: '7.30 h - 21.30 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Sarajevo',
    address: 'Kasindolskog bataljona 2b',
    location: [43.8172659, 18.358181100000024],
    workTimeWeekD: '8.00 h - 20.00 h',
    workTimeWeekP: '7.30 h - 20.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Sarajevo',
    address: 'Kasindolskog bataljona 2a',
    location: [43.817966, 18.358830],
    workTimeWeek: '8.00 h - 20.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Foča',
    address: 'Branka Radičevića bb',
    location: [43.50334,18.78155],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Trebinje',
    address: 'Gučina bb',
    location: [42.701036, 18.352862],
    workTimeWeek: '8.00 h - 17.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Mostar',
    address: 'Bišće polje bb',
    location: [43.31075250000004, 17.833229899999992],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Bijeljina',
    address: 'Šabačkih đaka 19',
    location: [44.7697941, 19.2157548],
    workTimeWeek: '7.00 h - 18.30 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Doboj',
    address: 'Dobojske brigade 1',
    location: [44.714497, 18.080738],
    workTimeWeekD: '8.00 h - 20.00 h',
    workTimeWeekP: '9.00 h - 20.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Brčko',
    address: 'Lončari bb',
    location: [44.9410719, 18.6793066],
    workTimeWeek: '8.00 h - 18.30 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Bihać',
    address: 'Ivana Franje Jukića 60',
    location: [44.80778130000001, 15.838926399999991],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Tuzla i Paket Shop Lukavac',
    address: 'Donji Bistarac bb, 75300 Lukavac',
    location: [44.513274, 18.554068],
    workTimeWeekD: '8.00 h - 18.00 h',
    workTimeWeekP: '9.00 h - 20.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Bugojno',
    address: 'Podgaj bb',
    location: [44.060231, 17.446427],
    workTimeWeekD: '8.00 h - 18.30 h',
    workTimeWeekP: '9.00 h - 18.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Prijedor',
    address: 'Svale bb',
    location: [44.9773353, 16.7327447],
    workTimeWeek: '8.00 h - 19.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Visoko',
    address: 'Ciglanska bb',
    location: [43.995064502178764, 18.170504880076635],
    workTimeWeekD: '8.00 h - 18.00 h',
    workTimeWeekP: '9.00 h - 18.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Zenica',
    address: 'Željezarska bb',
    location: [44.211580, 17.906124],
    workTimeWeek: '8.00 h - 19.30 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Banja Luka',
    address: 'Bulevar Desanke Maksimović 8, Starčevica',
    location: [44.763982249550736, 17.20574722517752],
    workTimeWeek: '8.00 h - 20.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },  {
    name: 'Paket Shop Centar (Banja Luka)',
    address: 'Vase Pelagića 10',
    location: [44.77063194706256, 17.195462147417913],
    workTimeWeek: '11.00 h - 19.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
];

export default class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: null
    };
  }

  // Called when a marker is clicked
  handleMarkerClick = (location) => {
    this.setState({ selectedLocation: location });
  };

  // Create a custom Leaflet icon using your image
  createCustomIcon = () => {
    return L.icon({
      iconUrl: mapIconImg,
      iconSize: [18, 24],
      iconAnchor: [9, 24],
      popupAnchor: [1, -34]
    });
  };

  render() {
    // Center the map (choose a coordinate that works for your data)
    const center = [43.9, 17.7721895];
    const { selectedLocation } = this.state;
    const customIcon = this.createCustomIcon();


    return (
        <div className='googleMap'>
          <Map center={center} zoom={7.5}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {locations.map((loc, i) => (
                <Marker
                    key={i}
                    position={loc.location}
                    icon={customIcon}
                    onClick={() => this.handleMarkerClick(loc)}
                />
            ))}
          </Map>

          {selectedLocation && (
              <div className="leafletModal">
                <div className="leafletBox">
                  <button
                      onClick={() => this.setState({ selectedLocation: null })}
                      className="fa fa-close"
                  />
                  <div className="textBox">
                    <p><span>{selectedLocation.name}</span></p>
                    <p>{selectedLocation.address}</p>
                    <h5 className="work-time-title">
                      {(selectedLocation.workTimeWeek ||
                          selectedLocation.workTimeWeekP ||
                          selectedLocation.deliveryTimeWeek) && 'Radno vrijeme'}
                    </h5>
                    <p style={{ fontWeight: 600 }}>
                      {(selectedLocation.workTimeWeek ||
                          selectedLocation.workTimeWeekP ||
                          selectedLocation.deliveryTimeWeek) && 'Radnim danima:'}
                    </p>
                    {selectedLocation.workTimeWeekD || selectedLocation.workTimeWeekP ? (
                        <>
                          <p>Distributivni centar: {selectedLocation.workTimeWeekD}</p>
                          <p>Paket Shop: {selectedLocation.workTimeWeekP}</p>
                        </>
                    ) : null}
                    {selectedLocation.deliveryTimeWeek ? (
                        <>
                          <p>Distributivni centar: {selectedLocation.workTimeWeek}</p>
                          <p>Paket Shop</p>
                          <p>Dostava paketa: {selectedLocation.deliveryTimeWeek}</p>
                          <p>Preuzimanje paketa: {selectedLocation.takingTimeWeek}</p>
                        </>
                    ) : null}
                    {!selectedLocation.deliveryTimeWeek && (
                        <p>{selectedLocation.workTimeWeek ? selectedLocation.workTimeWeek : null}</p>
                    )}
                    <p style={{ fontWeight: 600 }}>
                      {(selectedLocation.workTimeWeek ||
                          selectedLocation.workTimeWeekP ||
                          selectedLocation.deliveryTimeWeek) && 'Subotom:'}
                    </p>
                    {selectedLocation.workTimeSatudaryD || selectedLocation.workTimeSatudaryP ? (
                        <>
                          <p>Distributivni centar: {selectedLocation.workTimeSatudaryD}</p>
                          <p>Paket Shop: {selectedLocation.workTimeSatudaryP}</p>
                        </>
                    ) : null}
                    {selectedLocation.deliveryTimeWeek ? (
                        <p>{selectedLocation.workTimeSatudary}</p>
                    ) : null}
                    {selectedLocation.takingTimeSaturday ? (
                        <>
                          <p>Dostava paketa: {selectedLocation.deliveryTimeSaturday}</p>
                          <p>Preuzimanje paketa: {selectedLocation.takingTimeSaturday}</p>
                        </>
                    ) : null}
                    <p>{selectedLocation.workTimeSatudary ? selectedLocation.workTimeSatudary : null}</p>
                    <p style={{ fontWeight: 600 }}>
                      {selectedLocation.workTimeSunday && 'Nedjeljom:'}
                    </p>
                    <p>{selectedLocation.workTimeSunday ? selectedLocation.workTimeSunday : null}</p>
                    {selectedLocation.info && <p>{selectedLocation.info}</p>}
                    {/* Link to open location in external map; here using Google Map */}
                    <a
                        className="open-map-button"
                        href={`https://www.google.com/maps/search/?api=1&query=${selectedLocation.location[0]},${selectedLocation.location[1]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Otvori na Google mapi
                    </a>
                  </div>
                </div>
              </div>
          )}
        </div>
    );
  }
}
