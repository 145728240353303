import React, {Component} from 'react';
import {Field, reduxForm, formValueSelector} from 'redux-form'
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import Text from './fields/text';
import Select from './fields/select';
import Check from './fields/check';
import DateComponent from './fields/date';
import AnimatedButton from '../animatedButton';
import AutoSelect from 'react-select';
import Time from './fields/time';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';

const required = value => !(value === null || value === '' || value === undefined) ? undefined : "Polje je obavezno"
const minLength = (minLengthValue) => (value) =>
    value && value.length < minLengthValue
        ? `Mora da ima najmanje ${minLengthValue} karaktera`
        : undefined;

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: {touched, error},
    translate,
    invert
}) => (

    <Check
        label={label}
        translate={translate}
        invert={invert}
        {...input}
    />
)

const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: {touched, error},
}) => (

    <Text
        placeholder={placeholder}
        label={label}
        type={type}
        errorText={error}
        error={touched && error}

        {...input}
    />
)
const renderTimeField = ({
    input,
    placeholder,
    label,
    type,
    meta: {touched, error},
}) => (

    <Time
        placeholder={placeholder}
        label={label}
        type={type}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)
const renderAutoSelectField = ({ input, placeholder, label, meta: { touched, error }, children }) => (
    <AutoSelect
        onChange={input.onChange}
        options={children.map((item, index) => ({
            value: index,
            label: `${item.posta}, ${item.mjesto}, ${item.dc_name}`
        }))}
        error={touched && error}
        className={`auto-select ${touched && error ? 'invalid-auto-select' : ''}`}
        classNamePrefix="search-select"
        placeholder={placeholder}
    />
);


const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: {touched, error},
    children,
    disabled
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
        children={children}
        disabled={disabled}
    />
)
const renderDateField = ({
    input,
    placeholder,
    meta: {touched, error},
}) => (

    <DateComponent
        placeholder={placeholder}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)

class SelectingFormValuesForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zones: [],
            nextStep: false,
        }
    }

    //eyJraWQiOiI4OGI4NWQzYS04NDJhLTQ5MzgtOWRhYS1hMjkyNGRkY2JhYzEiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ3ZWJzaXRlQGxvY2FsaG9zdC5sb2NhbGhvc3QiLCJpc3MiOiJFdXJvRXhwcmVzcyBkLm8ubyIsImVtYWlsIjoid2Vic2l0ZUBsb2NhbGhvc3QubG9jYWxob3N0In0.d7JEF_lfxvsPwwvCtgjfr2KRtdxb62JpEOaZ3-n-LPdxpyVg1mJnysrNhqQT3-3pdvwxdg618RxBEDdFk0M20w

    componentDidMount() {
        fetch('https://app-api.euroexpress.ba/api/v1/pub/zones').then(
            (res) => res.json()).then((result) => {
            console.log('ZONES:', result)
            this.setState({
                zones: result
            })
        })

    }

    componentDidUpdate(prevProps, prevState) {;
        const { vals, change } = this.props;
        // Compare form values to determine if an update is necessary
        const shouldUpdateState = prevProps.vals && (
            vals.vrstaPosiljkeOznaka !== prevProps.vals.vrstaPosiljkeOznaka ||
            vals.tezina !== prevProps.vals.tezina ||
            vals.vrednostPosiljke !== prevProps.vals.vrednostPosiljke ||
            vals.duzina !== prevProps.vals.duzina ||
            vals.sirina !== prevProps.vals.sirina ||
            vals.visina !== prevProps.vals.visina ||
            vals.naplataPouzecem !== prevProps.vals.naplataPouzecem ||
            vals.povratOtpremnice !== prevProps.vals.povratOtpremnice ||
            vals.osiguranje !== prevProps.vals.osiguranje ||
            vals.otvaranjePosiljke !== prevProps.vals.otvaranjePosiljke ||
            vals.brzaDostava !== prevProps.vals.brzaDostava ||
            vals.iznosNaplatePouzecem !== prevProps.vals.iznosNaplatePouzecem ||
            vals.popustPs !== prevProps.vals.popustPs
        );

        // Check if an update is needed and make necessary changes
        if (vals && JSON.stringify(prevProps.vals) !== JSON.stringify(vals) && shouldUpdateState) {
            let updatedVals = { ...vals };

            // Additional logic for updating form values and state as needed

            // Example: Update 'timeLimited' field based on 'tender' field
            if (updatedVals.tender && !updatedVals.timeLimited) {
                change('timeLimited', true);
                updatedVals.timeLimited = true;
            }

            // Example: Reset 'brzaDostava' field if 'timeLimited' is selected
            if (updatedVals.brzaDostava && updatedVals.timeLimited) {
                updatedVals.brzaDostava = false;
            }
            if (!updatedVals.tezina) {
                updatedVals.tezina = 1;
            }
            updatedVals.vrstaPosiljkeSifra = updatedVals.vrstaPosiljkeOznaka === 'Dokument' ? 0 : 1;

            // Perform API call or data processing based on form values
            // Example fetch operation
            try {
                fetch(`https://gateway.euroexpress.ba/v1/calculator?calc=true`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'API-KEY': 'QxwNKo3eAMbmqdavYnlqGp1d'
                    },
                    body: JSON.stringify(updatedVals)
                })
                    .then(res => {
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.json();
                    })
                    .then(result => {
                        change('shipmentMetadata.tezinaZapremine', result.tezinaZapremine);
                        change('tmp', result);

                        this.setState({ price: result.iznosSaPdv });
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            } catch (error) {
                console.error('An unexpected error occurred:', error);
            }
        }
    }

    render() {

        const {handleSubmit} = this.props;

        let zones = [];
        for (let i = 0; i < this.state.zones.length; i++) {
            zones.push({
                label: `${this.state.zones[i].posta}, ${this.state.zones[i].mjesto}, ${this.state.zones[i].dc_name} `,
                value: i
            })
        }

        return (

            <form onSubmit={handleSubmit} className="form-wrapper">
                <Container>
                    <Row>
                        <Col lg="9">

                            <Container>
                                <Row>

                                    {this.state.nextStep ?
                                        <>
                                            <Col lg="12">
                                                <p>Datum slanja</p>
                                                <Field
                                                    name="date"
                                                    component={renderDateField}
                                                    placeholder="Kalendar"
                                                    validate={[required]}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p>Obveznik
                                                    plaćanja<span>*</span></p>
                                                <Field
                                                    name="paymentType"
                                                    type="text"
                                                    component={renderSelectField}
                                                    placeholder="Molim vas odaberite"
                                                    validate={[required]}

                                                >
                                                    <option
                                                        value="posiljalac"> Pošiljalac
                                                    </option>
                                                    <option
                                                        value="primalac"> Primalac
                                                    </option>
                                                </Field>
                                            </Col>

                                            <Col lg="12">
                                                <p className="title-big">PODACI
                                                    O POŠILJAOCU</p>
                                            </Col>

                                            <Col lg="12">
                                                <p>Ime i prezime<span>*</span>
                                                </p>
                                                <Field
                                                    name="senderInformation.name"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ime i prezime"
                                                    validate={[required, minLength(2)]}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p>Adresa<span>*</span></p>

                                                <Field
                                                    name="senderInformation.address"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ulica"
                                                    validate={[required,  minLength(2)]}

                                                />
                                            </Col>


                                            <Col lg="6">
                                                <p>Mjesto<span>*</span></p>
                                                {this.state.zones &&  <Field
                                                    onChange={(e) => {
                                                        this.props.change(
                                                            'senderInformation.postalCode',
                                                            this.state.zones[e.value].posta);
                                                        this.props.change(
                                                            'senderInformation.postalCity',
                                                            this.state.zones[e.value].dc_name);
                                                        this.props.change(
                                                            'senderInformation.city',
                                                            this.state.zones[e.value].mjesto);

                                                    }}
                                                    name="senderInformation.city"
                                                    type="text"
                                                    component={renderAutoSelectField}
                                                    placeholder="Mjesto"
                                                    validate={[required]}
                                                >
                                                    {this.state.zones}
                                                </Field> }


                                            </Col>


                                            <Col lg="6">
                                                <p>Telefon<span>*</span></p>
                                                <Field
                                                    name="senderInformation.phone"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Broj telefona"
                                                    validate={[required,  minLength(9)]}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p className="title-big">PODACI
                                                    O PRIMAOCU</p>
                                            </Col>

                                            <Col lg="12">
                                                <p>Ime i prezime<span>*</span>
                                                </p>
                                                <Field
                                                    name="receiverInformation.name"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ime i prezime"
                                                    validate={[required, minLength(2)]}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p>Adresa<span>*</span></p>

                                                <Field
                                                    name="receiverInformation.address"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ulica"
                                                    validate={[required, minLength(2)]}

                                                />
                                            </Col>


                                            <Col lg="6">
                                                <p>Mjesto<span>*</span></p>
                                                {this.state.zones &&  <Field
                                                    onChange={(e) => {
                                                        this.props.change('receiverInformation.postalCode', this.state.zones[e.value].posta);
                                                        this.props.change('receiverInformation.postalCity', this.state.zones[e.value].dc_name);
                                                        this.props.change('receiverInformation.city', this.state.zones[e.value].mjesto);

                                                    }}
                                                    name="receiverInformation.city"
                                                    type="text"
                                                    component={renderAutoSelectField}
                                                    placeholder="Mjesto"
                                                    validate={[required]}
                                                >
                                                    {this.state.zones}
                                                </Field> }



                                            </Col>


                                            <Col lg="6">
                                                <p>Telefon<span>*</span></p>
                                                <Field
                                                    name="receiverInformation.phone"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Broj telefona"
                                                    validate={[required,  minLength(9)]}

                                                />
                                            </Col>
                                            <Col lg="3">
                                                <AnimatedButton type="submit"
                                                                buttonText="POŠALJI"></AnimatedButton>
                                            </Col>


                                        </>
                                        :
                                        <>


                                            <Col lg="12">
                                                <p>Vrsta pošiljke<span>*</span>
                                                </p>
                                                <Field
                                                    name="shipmentMetadata.vrstaPosiljkeOznaka"
                                                    type="text"
                                                    component={renderSelectField}
                                                    placeholder="Molim vas odaberite"
                                                    validate={[required]}

                                                >
                                                    <option
                                                        value="Paket"> Paket
                                                    </option>
                                                    <option
                                                        value="Dokument"> Dokument
                                                    </option>
                                                </Field>
                                            </Col>
                                            <Col lg="12">
                                                <p>Opis sadržaja Vaše pošilje
                                                    *</p>

                                                <Field
                                                    name="shipmentMetadata.opisPosiljke"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder=""
                                                    validate={[required]}

                                                ></Field>
                                            </Col>

                                            {this.props.vals
                                            && this.props.vals.vrstaPosiljkeOznaka
                                            == "Paket" ?
                                                <>
                                                    <Col lg="12">
                                                        <p>Dimenzije</p>
                                                        <p className="sub">Poželjno
                                                            je unijeti i
                                                            dimenzije Vaše
                                                            pošiljke kako biste
                                                            dobili precizniji
                                                            iznos transporta</p>
                                                    </Col>

                                                    <Col lg="4">
                                                        <Field
                                                            name="shipmentMetadata.duzina"
                                                            type="number"
                                                            component={renderTextField}
                                                            placeholder="dužina (cm)"
                                                            validate={[required]}

                                                        ></Field>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Field
                                                            name="shipmentMetadata.sirina"
                                                            type="number"
                                                            component={renderTextField}
                                                            placeholder="širina (cm)"
                                                            validate={[required]}

                                                        ></Field>
                                                    </Col>

                                                    <Col lg="4">
                                                        <Field
                                                            name="shipmentMetadata.visina"
                                                            type="number"
                                                            component={renderTextField}
                                                            placeholder="visina (cm)"
                                                            validate={[required]}

                                                        ></Field>
                                                    </Col>


                                                    <Col lg="12">
                                                        <p>Volumetrijska masa (u
                                                            kg)</p>
                                                        <p className="sub">Volumetrijska
                                                            težina se automatski
                                                            obračunava na osnovu
                                                            mase.</p>
                                                        <Field
                                                            name="shipmentMetadata.tezinaZapremine"
                                                            type="number"
                                                            component={renderTextField}
                                                            placeholder="d x š x v/5000"
                                                            validate={[required]}

                                                        />
                                                    </Col>

                                                    <Col lg="12">
                                                        <p>Masa (u
                                                            kg)<span>*</span>
                                                        </p>
                                                        <Field
                                                            name="shipmentMetadata.tezina"
                                                            type="number"
                                                            component={renderTextField}
                                                            placeholder="0"
                                                            validate={[required]}

                                                        />
                                                    </Col>
                                                </>
                                                :
                                                null
                                            }

                                            <Col lg="6">
                                                <p>Vrijednost
                                                    Pošiljke<span>*</span></p>
                                                <Field
                                                    name="shipmentMetadata.vrednostPosiljke"
                                                    type="number"
                                                    component={renderTextField}
                                                    placeholder="0"
                                                    validate={[required]}

                                                />
                                            </Col>
                                            <Col lg="6" xs="12"
                                                 className="check-wrap">

                                                <Field
                                                    name="shipmentMetadata.osiguranje"
                                                    component={renderCheckField}
                                                    label="Dodatno osiguranje"
                                                    validate={[required]}

                                                />
                                            </Col>

                                            <Col lg="12">
                                                <p>Otvaranje
                                                    pošiljke<span>*</span></p>
                                                <p className="sub">Otvaranje
                                                    pošiljke podrazumijeva da
                                                    primalac može samo vizuelno
                                                    da pregleda sadržaj
                                                    pošiljke, bez isprobavanja i
                                                    uključivanja</p>


                                            </Col>

                                            <Col lg="3" xs="6" className="">

                                                <Field
                                                    name="shipmentMetadata.otvaranjePosiljke"
                                                    component={renderCheckField}
                                                    label="Da"
                                                    validate={[required]}

                                                />
                                            </Col>
                                            <Col lg="3" xs="6" className="">

                                                <Field
                                                    name="shipmentMetadata.otvaranjePosiljke"
                                                    invert
                                                    component={renderCheckField}
                                                    label="Ne"
                                                    validate={[required]}

                                                />
                                            </Col>
                                            <Col lg="12">
                                                <p>Popust na EuroExpress
                                                    lokaciju</p>
                                                <p className="sub">Otvaranje
                                                    pošiljke podrazumijeva da
                                                    primalac može samo vizuelno
                                                    da pregleda sadržaj
                                                    pošiljke, bez isprobavanja i
                                                    uključivanja</p>
                                                <Field
                                                    name="shipmentMetadata.popustPs"
                                                    type="text"
                                                    component={renderSelectField}
                                                    placeholder="Molim vas odaberite"
                                                    validate={[required]}

                                                >
                                                    <option value="0">Šaljem
                                                        standardno
                                                    </option>
                                                    <option value="1">Šaljem iz
                                                        paket šopa/magacina
                                                    </option>
                                                    <option value="2">Šaljem u
                                                        paket šop/magacina
                                                    </option>
                                                    <option value="3">Šaljem iz
                                                        paket šopa/magacina u
                                                        paket šop/magacin
                                                    </option>
                                                </Field>
                                            </Col>

                                            <Col lg="6" className="check-align">
                                                <p className="title">DODATNE
                                                    USLUGE</p>
                                                <Field
                                                    name="shipmentMetadata.naplataPouzecem"
                                                    component={renderCheckField}
                                                    label="Naplata pouzećem"

                                                />

                                                {this.props.vals
                                                && this.props.vals.naplataPouzecem
                                                    ?
                                                    <div>
                                                        <Field
                                                            name="shipmentMetadata.iznosNaplatePouzecem"
                                                            type="number"
                                                            component={renderTextField}
                                                            placeholder="Iznos naplate"
                                                            validate={[required]}

                                                        />
                                                    </div>

                                                    :

                                                    null
                                                }


                                                <Field
                                                    name="shipmentMetadata.povratOtpremnice"
                                                    component={renderCheckField}
                                                    label="Povrat otpremnice"

                                                />

                                                <Field
                                                    name="shipmentMetadata.timeLimited"
                                                    component={renderCheckField}
                                                    label="Vremenski određena dostava"

                                                />

                                                {this.props.vals
                                                && this.props.vals.timeLimited ?
                                                    <Container style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0
                                                    }}>
                                                        <Row>
                                                            <Col lg="6">
                                                                <p>Od</p>
                                                                <Field
                                                                    name="shipmentMetadata.timeLimitedFrom"
                                                                    type="text"
                                                                    component={renderTimeField}
                                                                    placeholder="Od"

                                                                />
                                                            </Col>
                                                            <Col lg="6">
                                                                <p>Do</p>

                                                                <Field
                                                                    name="shipmentMetadata.timeLimitedTo"
                                                                    type="text"
                                                                    component={renderTimeField}
                                                                    placeholder="Do"

                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    :

                                                    null
                                                }


                                                <Field
                                                    name="shipmentMetadata.brzaDostava"
                                                    component={renderCheckField}
                                                    label="Ubrzana dostava (do 12h)"

                                                />
                                                {/* <Field
                                                    name="shipmentMetadata.dostavaVikendom"
                                                    component={renderCheckField}
                                                    label="Dostava subotom"

                                                /> */}

                                            </Col>

                                            <Col lg="6"></Col>

                                            {(this.props.vals
                                                && this.props.vals.vrstaPosiljkeOznaka
                                                == "Dokument"
                                                && this.props.vals.vrednostPosiljke)
                                            || (this.props.vals
                                                && this.props.vals.vrstaPosiljkeOznaka
                                                == "Paket"
                                                && this.props.vals.tezina
                                                && this.props.vals.vrednostPosiljke)
                                                ?
                                                <Col lg="3">
                                                    <AnimatedButton
                                                        type="button"
                                                        buttonText="SLEDEĆI KORAK"
                                                        handleClick={() => {
                                                            if (this.props.vals
                                                                && this.props.vals.naplataPouzecem
                                                                && !this.props.vals.iznosNaplatePouzecem) {
                                                                alert(
                                                                    'Molimo vas da unesete iznos naplate pouzećem.')
                                                                return;
                                                            }

                                                            this.setState(
                                                                {nextStep: true});
                                                            window.scrollTo({
                                                                top: 0,
                                                                behavior: 'smooth'
                                                            })
                                                        }}></AnimatedButton>
                                                </Col>
                                                :
                                                null
                                            }
                                        </>
                                    }

                                </Row>
                            </Container>
                        </Col>

                        <Col lg="3">
                            <div className="price-modal">
                                <h6>Izračunata cijena dostave</h6>
                                <h3> {this.state.price ? this.state.price
                                    : '...'} KM</h3>
                                <p>* U cijene su uračunati PDV i takse</p>
                                <p>* Cijene su informativnog karaktera</p>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </form>
        )
    }
}

/*
export default reduxForm({
            form: 'sendPackageForm'  // a unique identifier for this form
    })(sendPackageForm)
    */

// Decorate with redux-form
SelectingFormValuesForm = reduxForm({
    form: 'selectingFormValues' // a unique identifier for this form
})(SelectingFormValuesForm)

// Decorate with connect to read form values
const selector = formValueSelector('selectingFormValues') // <-- same as form name
SelectingFormValuesForm = connect(state => {
    const values = selector(state, 'shipmentMetadata'
    )
    return {
        vals: values
    }
})(SelectingFormValuesForm)

export default SelectingFormValuesForm
